<div class="-oneX-container" id="mainContent" data-component="claim-found-components">
    <div class="-oneX-row no-gutters  ">
        <div tabindex="-1" class="-oneX-col-12">
            <div tabindex="0" class=" -oneX-heading--h3" id="claimFound" style="padding-top: 5px;">Claim Found
            </div>
        </div>
    
        <div class="-oneX-container">   
            <app-loss-details></app-loss-details>     
        </div>
        <div class="-oneX-container">
            <app-payments></app-payments>
        </div>
        <div class="-oneX-container">
            <app-claim-handler></app-claim-handler>     
        </div>
        <div class="-oneX-container"> 
            <app-claim-information></app-claim-information>     
        </div>
        
    </div>
    <br>
    <div class="-oneX-row -oneX-justify-content-center">
        <button tabindex="0" id="buttonid" class="-oneX-btn-primary -oneX-btn-fit-content"
            type="submit" (click)="goToSearch()">Search another claim</button>
    </div>
</div>