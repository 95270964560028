import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Payload } from '../../classes/payload.model';
import { NamePipe } from '../../pipes/name.pipe';
import { PhonePipe } from '../../pipes/phone.pipe';
import { FaxPipe } from '../../pipes/fax.pipe';
import { AddressPipe } from '../../pipes/address.pipe';

@Component({
  selector: 'app-claim-handler',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './claim-handler.component.html',
  styleUrl: './claim-handler.component.css'
})
export class ClaimHandlerComponent implements OnInit{
  claimHandler;
  name: String;
  phone: String;
  address: String
  fax: String;
  errorMessage: string;
  error: boolean;

  constructor(private payload: Payload, private namePipe: NamePipe, private phonePipe: PhonePipe, private faxPipe: FaxPipe, private addressPipe: AddressPipe) {}

  ngOnInit(): void {
    this.claimHandler = this.payload.getClaimHandler();
    this.name = this.namePipe.transform(this.claimHandler['name']['first_name'], this.claimHandler['name']['last_name'] )
    this.phone = this.phonePipe.transform(this.claimHandler['phone']['number'], this.claimHandler['phone']['area_code'], this.claimHandler['phone']['extension'])
    this.fax = this.faxPipe.transform(this.claimHandler['fax']['number'],this.claimHandler['fax']['area_code'],this.claimHandler['fax']['extension'])
    this.address = this.addressPipe.transform(this.claimHandler['mailing_address']['street'],this.claimHandler['mailing_address']['city'],this.claimHandler['mailing_address']['state'],this.claimHandler['mailing_address']['zip_code'])
  }
}
