import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class Payload {

    private claimNumber: any;
    private dol: any;
    private status: any;
    private lossLocation: any;
    private payments: any[];
    private claimHandler: any;
    
    getClaimNumber(){
        return this.claimNumber;
    }

    setClaimNumber(claimNumber){
        this.claimNumber = claimNumber;
    }

    getDol(){
        return this.dol;
    }

    setDol(dol){
        this.dol = dol;
    }

    getStatus(){
        return this.status;
    }

    setStatus(status){
        this.status = status;
    }

    getLossLocation(){
        return this.lossLocation;
    }

    setLossLocation(lossLocation){
        this.lossLocation = lossLocation;
    }

    getPayments(){
        return this.payments;
    }

    setPayments(payments){
        this.payments = payments;
    }

    getClaimHandler(){
        return this.claimHandler;
    }

    setClaimHandler(claimHandler){
        this.claimHandler = claimHandler;
    }
}
