import { Component, OnInit } from '@angular/core';
import { ClaimsApiService } from '../../services/Claim/claims-api.service';
import { CommonModule } from '@angular/common';
import { Request } from '../../classes/request.model';

@Component({
  selector: 'app-claim-information',
  standalone: true,
  imports: [CommonModule],
  providers: [ClaimsApiService],
  templateUrl: './claim-information.component.html',
  styleUrl: './claim-information.component.css'
})
export class ClaimInformationComponent implements OnInit{
  claimNumber: string;
  dateOfLoss: string;
  name: string;
  errorMessage: string;
  error: boolean
  
  constructor(private request: Request) {}

  ngOnInit(): void {
    this.claimNumber = this.request.getClaimNumber()
    this.dateOfLoss = this.request.getDol();
    this.name = this.request.getName();
  }
}
