<div class="-oneX-container">
  <div class="-oneX-row -oneX-justify-content-start -oneX-justify-content-start">
    <h5 tabindex="0" id="claimInfo"><i>Requested Claim Information</i></h5></div>
    <div class="-oneX-row">
      <div tabindex="-1" class="-oneX-col-md-4" aria-labelledby="claimNumber">
        <div tabindex="0" id="claimNumberField" class="-oneX-header" *ngIf="!error">Claim Number: </div>
        <div tabindex="0" id="statusField" class="-oneX-details"
          *ngIf="!error">{{claimNumber}}</div>
        <div tabindex="0" id="claimNumberField" *ngIf="error">{{errorMessage}}</div>
        <div tabindex="0" id="claimNumber"> </div>
      </div>

      <div tabindex="-1" class="-oneX-col-md-4" aria-labelledby="dateOfLoss">
        <div tabindex="0" id="dateOfLossField" class="-oneX-header" *ngIf="!error">Date of Loss: </div>
        <div tabindex="0" id="statusField" class="-oneX-details"
          *ngIf="!error">{{dateOfLoss}}</div>
        <div tabindex="0" id="dateOfLossField" class="-oneX-details" *ngIf="error">{{errorMessage}}</div>
        <div tabindex="0" id="dateOfLoss"> </div>
      </div>

      <div tabindex="-1" class="-oneX-col-md-4" aria-labelledby="name">
        <div tabindex="0" id="nameField" class="-oneX-header" *ngIf="!error" style="width: max-content;">Policyholder Last Name/Organization Name:</div>
        <div tabindex="0" id="statusField" class="-oneX-details"
          *ngIf="!error">{{name}}</div>
        <div tabindex="0" id="nameField" class="-oneX-details" *ngIf="error">{{errorMessage}}</div>
        <!-- <div tabindex="0" id="name"> </div> -->
      </div>
    </div>
</div>