import { ValidatorFn, FormGroup, AbstractControl, ValidationErrors } from "@angular/forms";
import { numericRegEx, alphabeticRegEx, alphabeticOrNumeric, emptyOrAlphabetic, noBlankFieldRegEx } from "./regex";
import moment from "moment";

export function claimNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const claimNumber = control.value.replace(/-/g, '');
        if (!claimNumber) { return null; }
        const firstTwoDigits = numericRegEx.test(claimNumber.substring(0,1))
        const restDigits = alphabeticRegEx.test(claimNumber.substring(1,10))
        const correctFormat = firstTwoDigits && !restDigits
        return !correctFormat ? { incorrectFormat: 'Incorrect format.  Enter a valid Claim Number in 99-XXXX-XXX format.' } : null;
    }
}

export function dateOfLossValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const dol = control.value;
        if (!dol) { return null; }
        const date = moment(dol, "MM-DD-YYYY")
        const incorrectDate = date.isValid()
        return !incorrectDate ? { incorrectDate: true
     } : null;
    }
}

export function futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const dol = control.value;
        if (!dol) { return null; }
        const todaysDate = new Date()
        const futureDate = moment(new Date(dol)).isAfter(todaysDate)
        return futureDate ? { futureDate: true,
     } : null;
    }
}