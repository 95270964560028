import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { AddressPipe } from './pipes/address.pipe';
import { NamePipe } from './pipes/name.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { FaxPipe } from './pipes/fax.pipe';
import { TitleCasePipe } from '@angular/common';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideClientHydration(),
    provideHttpClient(),
    AddressPipe,
    NamePipe,
    PhonePipe,
    FaxPipe,
    TitleCasePipe
  ]
};
